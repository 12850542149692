import Header1 from "./modules/header1";
import Block1 from "./modules/block1";
import Cta1 from "./modules/cta1";
import Top1 from "./modules/top1";

const init = () => {
  new Header1();
  new Block1();
  new Cta1();
  new Top1();
};

window.addEventListener("DOMContentLoaded", () => {
  init();
});
