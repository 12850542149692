import {
  gsap
} from "gsap";
import {
  ScrollTrigger
} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
class Top1 {

  constructor() {
    if (document.querySelector('.-top')) {
      this.initialize();
    }
  }

  initialize() {

    var kv1 = gsap.timeline({
      scrollTrigger: {
        trigger: ".p-kv1",
        start: 'top center'
      }
    });

    kv1
      .from('.p-kv1__inner1', {
        clipPath: 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)',
        opacity: 0,
        duration: 1.5,
        ease: 'Expo.easeInOut',
      })
      .from(['.p-kv1__catch1', '.p-kv1__button1', '.p-kv1__layer1'], {
        opacity: 0,
        x: -50,
        duration: 1.5,
        ease: 'Expo.easeOut',
      }, "<1")

    gsap.from('.p-block1', {
      opacity: 0,
      y: -50,
      duration: 1.5,
      ease: 'Expo.easeOut',
      scrollTrigger: {
        trigger: '.p-block1',
        start: 'top+=200 bottom',
      }
    });

    gsap.from('.p-block2__title1', {
      opacity: 0,
      y: -50,
      duration: 1.5,
      ease: 'Expo.easeOut',
      scrollTrigger: {
        trigger: '.p-block2__title1',
        start: 'top+=200 bottom',
      }
    });

    const blk2 = document.querySelectorAll('.p-block2__unit1');

    blk2.forEach(function (el, index) {
      gsap.from(el, {
        opacity: 0,
        y: -50,
        duration: 1.5,
        ease: 'Expo.easeOut',
        scrollTrigger: {
          trigger: el,
          start: 'top+=200 bottom',
        }
      });
    });

    gsap.from('.p-block3__title1', {
      opacity: 0,
      y: -50,
      duration: 1.5,
      ease: 'Expo.easeOut',
      scrollTrigger: {
        trigger: '.p-block3__title1',
        start: 'top+=200 bottom',
      }
    });

    gsap.from('.p-block3__text1', {
      opacity: 0,
      y: -50,
      duration: 1.5,
      ease: 'Expo.easeOut',
      scrollTrigger: {
        trigger: '.p-block3__text1',
        start: 'top+=200 bottom',
      }
    });

    gsap.from(document.querySelectorAll('.p-block3__unit1'), {
      opacity: 0,
      y: -50,
      duration: 1.5,
      ease: 'Expo.easeOut',
      stagger: 0.20,
      scrollTrigger: {
        trigger: '.p-block3__unit1',
        start: 'top+=300 bottom',
      }
    });

    gsap.from('.p-block4__title1', {
      opacity: 0,
      y: -50,
      duration: 1.5,
      ease: 'Expo.easeOut',
      scrollTrigger: {
        trigger: '.p-block4__title1',
        start: 'top+=200 bottom',
      }
    });

    gsap.from(document.querySelectorAll('.p-block4__unit1'), {
      opacity: 0,
      y: -50,
      duration: 1.5,
      ease: 'Expo.easeOut',
      stagger: 0.1,
      scrollTrigger: {
        trigger: '.p-block4__unit1',
        start: 'top+=200 bottom',
      }
    });

    gsap.from('.p-block4__button1', {
      y: -50,
      duration: 1.5,
      ease: 'Expo.easeOut',
      scrollTrigger: {
        trigger: '.p-block4__button1',
        start: 'top+=200 bottom',
      }
    });

  }

}

export default Top1;
