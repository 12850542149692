import Swiper from 'swiper/bundle';

class Carousel {

  constructor() {
    if (document.querySelector('.p-block1__slider1')) {
      this.swiper = document.querySelectorAll('.p-block1__slider1');
      this.initialize();
    }
  }

  initialize() {
    this.swiper.forEach(el => {
      const swiper = new Swiper(el.querySelector('.swiper'), {
        speed: 750,
        spaceBetween: 30,
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: -1,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: -1,
          },
        },
        pagination: {
          el: el.querySelector('.swiper-pagination'),
        },
        navigation: {
          nextEl: el.querySelector('.swiper-button-next'),
          prevEl: el.querySelector('.swiper-button-prev'),
        },
      });
    });
  }

}

export default Carousel;
