import {
  gsap
} from "gsap";
import {
  ScrollTrigger
} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

class Cta1 {

  constructor() {
    if (document.querySelector('.p-cta1')) {
      this.mediaQueryList = window.matchMedia("(max-width: 767px)");
      this.el = document.querySelector('.p-cta1');
      this.trigger = null;
      this.initialize();
    }
  }

  initialize() {
    this.mediaQueryList.addEventListener("change", this.update.bind(this));
    this.update();
  }

  update() {
    if (this.mediaQueryList.matches) {
      gsap.set(this.el, {
        x: 0,
        y: 100,
        opacity: 0,
      });
      if (this.trigger) {
        this.trigger.kill();
      }
      this.trigger = ScrollTrigger.create({
        trigger: "body",
        start: 'top top-=100px',
        onEnter: () => gsap.to(this.el, {
          x: 0,
          y: 0,
          opacity: 1,
          duration: 0.7,
          ease: 'power4.out',
        }),
        onLeaveBack: () => gsap.to(this.el, {
          x: 0,
          y: 100,
          opacity: 0,
          duration: 0.7,
          ease: 'power4.out',
        }),
      });
    } else {
      gsap.set(this.el, {
        x: 50,
        y: 0,
        opacity: 0,
      });
      if (this.trigger) {
        this.trigger.kill();
      }
      this.trigger = ScrollTrigger.create({
        trigger: "body",
        start: 'top top-=500px',
        onEnter: () => gsap.to(this.el, {
          x: 0,
          y: 0,
          opacity: 1,
          duration: 0.7,
          ease: 'power4.out',
        }),
        onLeaveBack: () => gsap.to(this.el, {
          x: 50,
          y: 0,
          opacity: 0,
          duration: 0.7,
          ease: 'power4.out',
        }),
      });
    }
  }

}

export default Cta1;
